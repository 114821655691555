import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Layout, Row, Select, Statistic, Tag } from "antd";
import { RootStore } from "../../../Store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import "../style/TopProductAttributes.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { getAllCategoryL3 } from "../../../actions/category/categoryActions";
import { TreeCategoryType } from "../../prices/types/AllPricesTypes";
import ApplyToAll from "../components/TopProductsAttributes/ApplyToAll";
import { tableWidth } from "../helpers/TopProductAttributes/tableWidth";
import TopProductAttributesTable from "../components/TopProductsAttributes/TopProductAttributesTable";
import CreateNewValues from "../components/TopProductsAttributes/CrateNewValue";
import numberWithCommas from "../../../shared/utils/numberUtils";

export type InputType = "range" | "select" | "multiselect" | "yesno";

export type PrimaryAttributesType<T> = {
    id: number;
    max_value: string;
    min_value: string;
    type: InputType;
    [key: string]: string | number | T;
    attribute_values: T;
};

export type AttributesType = {
    attr_value: string;
    attribute_allowed_value_id: null | number;
    attribute_id: number;
    attribute_name: string;
    attribute_type: string;
    max_value: number;
    min_value: number;
    product_attribute_id: string;
};

export type ProductType = {
    attr_value: string | null;
    attribute_allowed_value_id: number | null;
    attribute_id: number;
    attribute_type: string;
    max_value: string;
    min_value: string;
    [key: string]: string | number | null | AttributesType[];
    product_attribute_id: number | null;
    product_id: number;
    attributes: AttributesType[];
};

const TopProductAttributes: React.FC = () => {
    const [primaryAttributes, setPrimaryAttributes] = useState<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>([]);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [showApplyToAll, setShowApplyToAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [missingAttributes, setMissingAttributes] = useState<number[]>([]);
    const [searchData, setSearchData] = useState<{ search: string | undefined; search_include: string[]; search_exclude: string[]; or: boolean } | undefined>();

    const [loadingSearch, setLoadingSearch] = useState(false);
    const stateProduct = useSelector((state: RootStore) => state);
    const currentCountryId = stateProduct.dataLs.country ? stateProduct.dataLs.country.countryId : 1;
    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";
    //create new value
    const [openModal, setOpenModal] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState<{ name: string | undefined; attribute_id: number | undefined; product: ProductType } | null>(null);
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        const getPrimaryAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-primary-attributes-by-l3?l3_id=${selectedCategory}&country_id=${currentCountryId}`);
                setPrimaryAttributes(data.data);
            } catch (err) {
                console.log(err);
            }
        };

        selectedCategory && getPrimaryAttributes();
    }, [selectedCategory, currentCountryId]);

    const getProducts = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attributes/get-l3-products-primary-attributes?l3_id=${selectedCategory}&country_id=${currentCountryId}&page=${page}${
                    searchData?.search && searchData.search.length > 0 ? `&search=${searchData.search}` : ""
                }${searchData?.search_include && searchData?.search_include.length > 0 ? searchData?.search_include?.map((text) => `&search_include[]=${text}`)?.join("") : ""}${
                    searchData?.search_exclude && searchData?.search_exclude.length > 0 ? searchData?.search_exclude?.map((text) => `&search_exclude[]=${text}`)?.join("") : ""
                }${missingAttributes.length > 0 ? missingAttributes.map((text) => `&missing_attribute_ids[]=${text}`)?.join("") : ""}${searchData?.or ? `&or=${1}` : ""}`
            );

            setProducts(data.data.map((d: ProductType) => ({ ...d, attributes: d.attributes })));
            setLoading(false);
            setLoadingSearch(false);
        } catch (err) {
            console.log(err);
        }
    }, [selectedCategory, currentCountryId, page, searchData, missingAttributes]);

    useEffect(() => {
        const timer = setTimeout(() => {
            selectedCategory && getProducts();
        }, 50);
        return () => {
            clearTimeout(timer);
        };
    }, [selectedCategory, getProducts]);

    useEffect(() => {
        const getCount = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/get-l3-products-primary-attributes-count?l3_id=${selectedCategory}&country_id=${currentCountryId}${
                        searchData?.search && searchData.search.length > 0 ? `&search=${searchData.search}` : ""
                    }${searchData?.search_include && searchData?.search_include.length > 0 ? searchData?.search_include?.map((text) => `&search_include[]=${text}`)?.join("") : ""}${
                        searchData?.search_exclude && searchData?.search_exclude.length > 0 ? searchData?.search_exclude?.map((text) => `&search_exclude[]=${text}`)?.join("") : ""
                    }${missingAttributes.length > 0 ? missingAttributes.map((text) => `&missing_attribute_ids[]=${text}`)?.join("") : ""}${searchData?.or ? `&or=${1}` : ""}`
                );
                setCount(data.total);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getCount();
    }, [selectedCategory, currentCountryId, searchData, missingAttributes]);

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);

    const onChangeCategory = (e: number) => {
        setSelectedCategory(e);
        form.resetFields();
        setSearchData(undefined);
    };

    const handleSearch = (e: { search: string; search_include: string[]; search_exclude: string[]; or: boolean }) => {
        setLoadingSearch(true);
        setSearchData(e);
        if (e.search?.trim()) {
            setShowApplyToAll(true);
        } else {
            setShowApplyToAll(false);
        }
        setPage(1);
    };

    const showModal = (attributeObj: { name: string | undefined; attribute_id: number | undefined; product: ProductType }) => {
        setNewAttributeValue(attributeObj);
        setOpenModal(true);
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const onPaginationChange = (page: number) => {
        setPage(page);
    };

    const watchInclude = Form.useWatch("search_include", form);

    const handleChangeInclude = (e: string[]) => {
        if (e.length < 2) {
            form.resetFields(["or"]);
        }
    };

    //const filteredOptions = primaryAttributes.filter((o) => !missingAttributes.includes(o.id));

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Top Product attributes" />
            <CreateNewValues
                openModal={openModal}
                handleCancel={handleCancel}
                newAttributeValue={newAttributeValue}
                countries={stateProduct.countries}
                currentCountryName={currentCountryName}
                primaryAttirbutes={primaryAttributes}
                setPrimaryAttributes={setPrimaryAttributes}
                products={products}
                setProducts={setProducts}
            />
            <div style={{ backgroundColor: "white", margin: "0.5rem 1rem", minHeight: "87vh" }}>
                <Divider>Attribute product list</Divider>
                <Row justify="center">
                    <Col span={23} style={{ border: "1px solid lightgray", backgroundColor: "whitesmoke", padding: "1rem", display: "flex", position: "relative", flexDirection: "column" }}>
                        <div>
                            <Form layout="inline">
                                <Form.Item label="Category" style={{ marginLeft: "1rem", alignItems: "start", width: "400px" }}>
                                    <Select
                                        showSearch
                                        placeholder="Select a category"
                                        optionFilterProp="label"
                                        options={stateProduct.categoriesL3.data?.map((l3: TreeCategoryType) => ({ label: `${l3.id}: ${l3[`name_${currentCountryName}`]}`, value: l3.id }))}
                                        style={{ width: "400px" }}
                                        showArrow={false}
                                        onChange={onChangeCategory}
                                    />
                                </Form.Item>
                                <Form.Item label="Missing Attributes" style={{ marginLeft: "1rem", alignItems: "start", width: "500px" }}>
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Missing Attributes"
                                        options={primaryAttributes.map((attr) => ({
                                            label: attr[`name_${currentCountryName}`],
                                            value: attr.id,
                                        }))}
                                        onChange={(e) => setMissingAttributes(e)}
                                        optionFilterProp="label"
                                    />
                                </Form.Item>
                            </Form>

                            <Statistic
                                title="Total"
                                value={count && numberWithCommas(String(count).replace(".", ","))}
                                valueStyle={{ color: "green" }}
                                style={{ position: "absolute", right: "40px", top: "3px" }}
                            />
                        </div>
                        <div style={{ marginTop: "1rem", backgroundColor: "#EEEEEE", padding: "1rem 0", borderRadius: "5px" }}>
                            <Form form={form} onFinish={handleSearch} layout="inline">
                                <Form.Item name="search" label="Search" style={{ marginLeft: "1rem", alignItems: "start" }}>
                                    <Input placeholder="Search by product name" style={{ width: "400px" }} allowClear />
                                </Form.Item>
                                <Form.Item name="search_include" label="Include" style={{ width: "400px" }}>
                                    <Select
                                        allowClear
                                        mode="tags"
                                        tagRender={(props) => {
                                            const { label, closable, onClose } = props;
                                            const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            };
                                            return (
                                                <Tag color={"green"} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginInlineEnd: 4 }}>
                                                    {label}
                                                </Tag>
                                            );
                                        }}
                                        placeholder="Exclude words"
                                        options={[]}
                                        dropdownStyle={{ display: "none" }}
                                        onChange={(e) => handleChangeInclude(e)}
                                    />
                                </Form.Item>
                                <Form.Item name="or" label="Or" valuePropName="checked" initialValue={false}>
                                    <Checkbox disabled={watchInclude && watchInclude.length > 1 ? false : true} />
                                </Form.Item>
                                <Form.Item name="search_exclude" label="Exclude" style={{ width: "400px" }}>
                                    <Select
                                        allowClear
                                        mode="tags"
                                        tagRender={(props) => {
                                            const { label, closable, onClose } = props;
                                            const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            };
                                            return (
                                                <Tag color={"red"} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginInlineEnd: 4 }}>
                                                    {label}
                                                </Tag>
                                            );
                                        }}
                                        placeholder="Exclude words"
                                        options={[]}
                                        dropdownStyle={{ display: "none" }}
                                    />
                                </Form.Item>

                                <Form.Item style={{ marginLeft: "1rem" }}>
                                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />} loading={loadingSearch} disabled={!selectedCategory} />
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>

                    {showApplyToAll && primaryAttributes.length > 0 && (
                        <ApplyToAll
                            primaryAttributes={primaryAttributes}
                            currentCountryName={currentCountryName}
                            currentCountryId={currentCountryId}
                            selectedCategory={selectedCategory}
                            searchData={searchData}
                            getProducts={getProducts}
                            setPrimaryAttributes={setPrimaryAttributes}
                            stateProduct={stateProduct}
                            form={form}
                        />
                    )}

                    <Col span={tableWidth(primaryAttributes)}>
                        <Layout style={{ margin: "0.6rem 1rem" }}>
                            <Layout.Header className="ean-overview-header" />
                            <TopProductAttributesTable
                                primaryAttributes={primaryAttributes}
                                products={products}
                                setProducts={setProducts}
                                count={count}
                                currentCountryName={currentCountryName}
                                page={page}
                                loading={loading}
                                onPaginationChange={onPaginationChange}
                                showModal={showModal}
                            />
                        </Layout>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default TopProductAttributes;
